import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Typography,
  FormControl,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import { RiderKycApi } from "../../../API/RiderKycApi";
import ArrowBackIcon from "@mui/icons-material/ArrowBackTwoTone";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router";
import { useLocation } from "react-router";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function RiderDetailKyc() {
  const navigate = useNavigate();
  const location = useLocation();
  const [oldTlList, setOldTlList] = useState<any[]>([]);
  const [newTlList, setNewTlList] = useState<any[]>([]);
  const [selectOldName, setSelectOldName] = useState<any>();
  const [selectNewName, setSelectNewName] = useState<any>();
  const [riderId, setRiderId] = useState<string>("");
  const [disableOldTl, setDisableOldTl] = useState(false);
  const selectedValue = location.state?.selectedValue;
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [SnackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const fetchOldTlList = async () => {
      try {
        const response = await RiderKycApi.api.oldTlList(selectedValue);
        if (
          response.data.oldExecutiveIds &&
          response.data.oldExecutiveIds.length > 0
        ) {
          setOldTlList(response.data.oldExecutiveIds);
          setNewTlList(response.data.newExecutiveIds);
        }
      } catch (error) {
        console.error("Error fetching merchant list:", error);
      }
    };

    fetchOldTlList();
  }, [selectedValue]);

  const token = localStorage.getItem("access_token");

  const assignTl = async () => {
    const inputString = riderId; // Your input string
    const formateRiderId = inputString.split(",").map(Number); // Convert string to an array of integers

    const requestData = {
      newExecutive: selectNewName ||"",
      oldExecutive: selectOldName,
      executiveList: formateRiderId,
      change: selectedValue,
      disableOldExecutive: disableOldTl,
    };

    try {
      const response = await fetch(
        "https://stag-micro.bcykal.com/mobycy/clientDash/update/executive",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        setShowSuccessSnackbar(true);
        setSelectNewName("");  // Set the selected executive's ID here
        setSelectOldName("");
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || "Error creating store");
        setShowErrorSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Network error");
      setShowErrorSnackbar(true);
    }
  };

  const handleDisableOldTlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setDisableOldTl(checked); // Update the state
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowErrorSnackbar(false);
    setShowSuccessSnackbar(false);
  };
  const getLabel = () => {
    switch (selectedValue) {
      case "0":
        return "TL";
      case "1":
        return "CM";
      case "2":
        return "CH";
      case "3":
        return "OH";
      case "4":
        return "BH";
      default:
        return "TL";
    }
  };

  const getLabelMapping = () => {
    switch (selectedValue) {
      case "0":
        return "Rider";
      case "1":
        return "TL";
      case "2":
        return "CM";
      case "3":
        return "CH";
      case "4":
        return "OH";
    }
  };

  // Check if all required fields are filled
  const isSubmitDisabled = () => {
    return !selectOldName || !selectNewName ;
  };

  return (
    <>
      <div className="bg-gradient-to-r from-slate-100 to-slate-50 pl-8 pr-8 pb-8">
        <div style={{ height: "40px", width: "100%" }}>
          <Toolbar
            sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}
            className="bg-gradient-to-r from-slate-100 to-slate-50"
          >
            <Typography
              sx={{ flex: "1 1 75%", textAlign: "left" }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              <a
                onClick={() => navigate("/alluserReport")}
                className="cursor-pointer"
              >
                <ArrowBackIcon className="mr-4 mb-1 ml-7" />
              </a>
              <b>Change {getLabel()}</b> {/* Display the dynamic label */}
            </Typography>

            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                marginRight: "48px",
              }}
              component="div"
            >
              <HomeIcon className="mb-1" />
              {" / "} <span style={{ color: "#FF725E" }}>Dashboard</span>{" "}
              {" / "}
              Change {getLabel()}
            </Typography>
          </Toolbar>
        </div>
        <div className="bg-[#fff] p-8 rounded-lg pb-4 mb-8">
          <Grid container spacing={5} className="w-full flex justify-between">
            <Grid item md={4}>
              <div className="mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  Old {getLabel()} <sup style={{ color: "red" }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="merchant-select"
                    onChange={(e) => {
                      setSelectOldName(e.target.value);
                    }}
                    style={{
                      border: "1px solid #c0baba",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}
                  >
                    <option value="" selected>
                      Select Old {getLabel()}
                    </option>
                    {oldTlList.map((TL, index) => (
                      <option
                        key={index}
                        value={TL.userId}
                        data-merchant-id={TL.userId}
                        data-merchant-name={TL.name}
                      >
                        {TL.name}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-full mt-4">
                <p className="text-left mb-4 text-sm font-bold">
                  New {getLabel()} <sup style={{ color: "red" }}>*</sup>
                </p>
                <FormControl fullWidth>
                  <select
                    id="city"
                    onChange={(e) => {
                      setSelectNewName(e.target.value);
                    }}
                    style={{
                      border: 1,
                      borderColor: "#c0baba",
                      borderStyle: "solid",
                      padding: 7,
                      borderRadius: 3,
                      height: 56,
                    }}
                  >
                    <option value="" selected>
                      Select New {getLabel()}
                    </option>
                    {newTlList.map((TL, index) => (
                      <option
                        key={index}
                        value={TL.userId}
                        data-merchant-id={TL.userId}
                        data-merchant-name={TL.name}
                      >
                        {TL.name}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div>
                <p className="text-left mb-4 text-sm font-bold">
                  {getLabelMapping()} IDs 
                </p>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  className="w-full"
                  type="text" // Set input type to number
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow numbers up to 10 digits
                    setRiderId(value);
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <div className="w-full flex justify-end mt-5">
            <div className=" flex  items-center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={disableOldTl}
                    onChange={handleDisableOldTlChange} // Handle checkbox change
                  />
                }
                label={`Disable Old ${getLabel()}`}
              />
            </div>

            <div className="flex justify-between items-center">
              <div className="flex">
                <button
                  className={`px-12 py-4 text-white rounded-lg w-full bg-[#FE5B44] ${
                    isSubmitDisabled() ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={assignTl}
                  disabled={isSubmitDisabled()} // Disable button based on the check
                >
                  Submit
                </button>
              </div>
            </div>
            

            <Snackbar
              open={showSuccessSnackbar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                sx={{ width: "100%" }}
                onClose={handleClose}
                severity="success"
              >
                UPDATED SUCCESSFULLY!
              </Alert>
            </Snackbar>
            <Snackbar
              open={showErrorSnackbar}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                sx={{ width: "100%" }}
                onClose={handleClose}
                severity="error"
              >
                {SnackbarMessage.replace(/_/g, " ")}
              </Alert>
            </Snackbar>
          </div>
          <div className="text-left text-red-600">
              <p><b>Note: <sup style={{ color: "red" }}>*</sup></b></p>
              <ul>
                <li><b>1.</b> If you want to transfer all the users, keep the IDs field empty.
              </li>
              <li><b>2.</b> If you have to transfer only a few users, then enter their user
              IDs separated by a comma (,) in the IDs field </li>

              </ul>
            </div>
        </div>
      </div>
    </>
  );
}
